.animated-name {
  height: 10em;
  width: auto;
  padding: 0.5rem;
  margin-inline: auto;
}
.home-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.info-container {
  animation: pulse 1.5s 1.5s;
  align-items: center;
  justify-content: center;
}

@media (max-width: 30rem) {
  .info-container {
    height: min(100%, 70.5em);
    width: min(100%, 70.5em);
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: center;
  }
}

.intro-sub {
  font-size: 1.5rem;
  margin-top: 0;
}

.profile-picture {
  height: 30em;
  width: 30em;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid transparent;
  box-shadow: 0px 0px 10px #000000;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
}

.profile-picture:hover {
  border: 2px solid #ffa62b;
  box-shadow: 0px 0px 20px #ffa62b; 
}

.social-link {
  display: flex;
  font-size: 3.5em;
  padding: 0.5% 2% 10%;
}

.link-wrapper {
  height: fit-content;
  display: flex;
  justify-content: center;
}
