.btn-primary:hover {
  background: #ffa62b;
  transition: background 0.3s ease-in-out;
}
.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.navbar {
  position: fixed;
  max-width: 100%;
  opacity: 0.85;
  height: 50px;
  z-index: 12;
  // background-color: #222;
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  height: 6.4em;
  align-items: center;
  width: 100vw;
}

.menu-items {
  order: 2;
  display: flex;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.menu-items a {
  text-decoration: none;
  color: #f8f1f1;
  position: relative;
}

.menu-items a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; 
  bottom: -3px; 
  left: 0;
  background-color: #ffa62b; 
  transform: scaleX(0); 
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.menu-items a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 768px) {
  .navbar {
    opacity: 0.95;
    width: 40%;

  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: flex;
    height: 100vh;
    max-width: 12em;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 23px;
    width: 35px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #f8f1f1;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 100px;
    // background: #222;
    height: 100vh;
    max-width: 30rem;
    width: 95%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -15em;
    transition: transform 0.5s ease-in-out;
  }

  .navbar .menu-items li {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    color: #f8f1f1;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(35deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-35deg);
  }
}

@media (max-width: 500px) {
  html {
    font-size: 65%;
  }
}
