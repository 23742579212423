@import "animate.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,600");

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

body {
  background-color: #222;
  font-size: 1.5rem;
  color: #f8f1f1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}
.container {
  height: 85vh;
  height: 85svh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

input,
textarea,
.flat-button,
a {
  color: #f8f1f1;
  text-decoration: none;
}

.flat-button:hover {
  transition: 0.75s;
}
a:hover {
  color: #ffa62b;
  text-decoration: underline;
  transition: 0.75s;
}
