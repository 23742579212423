.about-container {
  font-size: 1.5em;
  justify-content: center;
  align-content: center;
  margin-inline: auto;
  margin-block: auto;
  margin-top: 10rem;
}

.about-paragraph {
  height: 100%;
  width: min(90%, 70.5em);
  margin-inline: auto;
  font-size: clamp(0.5rem, 2vw + 1rem, 1.5rem);
}

.about-picture-container {
  height: 50%;
  width: auto;
}

.about-picture {
  height: 30em;
  width: auto;
  border: 1px solid #f8f1f1;
  box-shadow: 0px 0px 10px #000000;
}
