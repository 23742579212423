.contact-form {
  width: 35rem;
  margin-top: 4rem;
  border: #f8f1f1 solid 1px;
  box-shadow: 0px 0px 10px #000000;
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 1em;
    margin: 0;
    overflow: hidden;
  }
}

.contact-title {
  font-size: 1.75em;
}

textarea,
input {
  width: 100%;
  font-size: 1.6em;
  background-color: transparent;
  border: 1px solid #f8f1f1;
}

input {
  height: 3.125em;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

textarea {
  height: 6.25em;
  resize: none;
}

.flat-button {
  width: 10em;
  padding: 0.625em 0.625em;
  background-color: transparent;
  font-size: 1.6em;
  letter-spacing: 2px;
  border: 1px solid #db6400;
  background-color: #db6400;
}

.flat-button:hover {
  border: solid 1px #ffa62b;
  background-color: #ffa62b;
}

::placeholder {
  text-align: center;
  color: #f8f1f1;
}
