.projects-container {
  height: min(85%, 70.5em);
  width: min(85%, 35.5em);
  padding-top: 2rem;
  display: grid;
  grid-gap: 1.6rem;
}

@media (width >= 64em) {
  .projects-container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2em;

    justify-content: center;
    align-content: center;
  }
}

.project-image {
  height: 10rem;
  width: 20rem;
  border: #f8f1f1 solid 1px;
  box-shadow: 0px 0px 10px #000000;
}

.project-sub,
.project-title .project-descrip {
  color: #f8f1f1;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.description-container {
  height: 3.125rem;
  width: auto;
  padding-bottom: 5rem;
  text-align: center;
}

.project-title:hover {
  color: #ffa62b;
}

.project-descrip {
  font-size: clamp(0.5rem, 2vw + 1rem, 1.25rem);
}
